import React, { useEffect } from 'react';
import { Link } from "gatsby"
import { graphql } from "gatsby"
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"

import { useSiteState } from '../lib/siteContext';

import { QueryFragments } from "../GraphQl/queryFragments" // eslint-disable-line

// Components

const EventTemplate = ({ pageContext, data: { page } }) => {
  const { title, description, postDate, images } = page || {}
  const { homeHeader, setHomeHeader, setLogoColor } = useSiteState();

  useEffect(() => {    
    if (homeHeader === true) {
      setHomeHeader(false);
    }
    document.documentElement.style.setProperty(`--color-logo`, '#18227B');
     document.documentElement.style.setProperty(`--doc-bg`, '#ffffff');          
     document.documentElement.style.setProperty(`--doc-text`, '#000000');  
  }, [setHomeHeader, homeHeader, setLogoColor]);

  return (
    <>      
      <div class="grid-std pt-40 lg:pt-72 xl:pt-96 pb-space-s text-fat-blue">
        <div className="row-start-1 col-start-2 col-end-8 lg:col-start-2 lg:col-end-17">
        {postDate}
        <h1 className="heading uppercase text-h5 md:text-h4 lg:text-h3 xl:text-h2">{title}</h1>
        {description && <p>{description}</p>}
        </div>
      </div>
      {images && (
          <div className="row-start-1 col-span-full mb-space-l">
              <ResponsiveMasonry columnsCountBreakPoints={{350: 1, 1400: 2, 2200: 3}} >
                <Masonry>
                {images.map((image, index) => (            
                <div className="p-gutter">
                    {image &&  <img className="w-full h-auto" src={image.asset.url} alt={image.alt} /> }
                </div>
                ))}   
                </Masonry>
            </ResponsiveMasonry>                 
          </div>
      )}
      {pageContext.next != null && (
        <div className="w-full flex justify-end text-h5 lg:text-h4 text-current px-gutter">
        <Link className="lg:w-1/2" node={pageContext.next} to={`/news/${pageContext.next.slug.current}`}>
          <span className="heading text-current uppercase pb-4 text-base">Next Event</span>
          <br/>
          {pageContext.next.title}
        </Link>
        </div>
      )}
    </>
  );
};

export default EventTemplate;

export const pageQuery = graphql`
  query eventQuery($slug: String!) {
    page: sanityEvent(slug: { current: { eq: $slug } }) {
      title      
      description
      postDate(formatString: "DD MMMM YYYY")      
      images {
          asset {
              url
          }
      }
    }    
  }
`;
 